<template>
  <LayoutSuperadmin>
    <template v-slot:title>Emails</template>
    <div class="p-3">
      <AppLoading v-if="items.length === 0 && loading" fillSpace></AppLoading>
      <div v-else class="emails m-auto">
        <div class="d-none col-12 d-md-block">
          <AppTable :headers="headers" :items="items">
            <template v-slot:item="{ shownItem }">
              <td class="bg-gray-light-4 d-flex align-items-center">
                {{ shownItem.email || 'N/A' }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('first_name') }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('last_name') }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('company') }}
              </td>
              <td class="">{{ shownItem.mobile_number }}</td>
              <td class="">
                {{ shownItem.created_at | readableDate(false) }}
                {{ shownItem.created_at | readableTime }}
              </td>
            </template>
          </AppTable>
        </div>
        <div class="d-md-none">
          <AppTable :headers="headers" :items="items" :itemsPerPage="8">
            <template v-slot:item="{ shownItem }">
              <td
                class="bg-gray-light-4 align-items-center"
                style="max-width: 90px; word-break: break-all"
              >
                {{ shownItem.email || 'N/A' }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('first_name') }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('last_name') }}
              </td>
              <td class="">
                {{ shownItem.information | findIndex('company') }}
              </td>
              <td class="">{{ shownItem.mobile_number }}</td>
              <td class="">
                {{ shownItem.created_at | readableDate(false) }}
                {{ shownItem.created_at | readableTime }}
              </td>
            </template>
          </AppTable>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import AppTable from '@/shared/elements/AppTable.vue';
import Users from '@/shared/api/Users';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import AppLoading from '@/shared/elements/AppLoading.vue';
import LayoutSuperadmin from '../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import _readableDateTime from '@/shared/mixins/_readableDateTime';

export default {
  name: 'SuperEmails',

  components: { AppTable, AppLoading, LayoutSuperadmin },

  mixins: [_appApiHelper, _readableDateTime],

  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email',
          sortable: false,
        },
        {
          text: 'First Name',
          value: 'first_name',
          sortable: false,
        },
        {
          text: 'Last Name',
          value: 'last_name',
          sortable: false,
        },
        {
          text: 'Company',
          value: 'company',
          sortable: false,
        },
        {
          text: 'Contact Number',
          value: 'contact_number',
          sortable: false,
        },
        {
          text: 'Registration Date',
          value: 'register_date',
          sortable: false,
        },
      ],
      items: [],
    };
  },

  filters: {
    findIndex(value, index) {
      if (value && value[index]) {
        return value[index];
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    async fetchSignups() {
      const response = await Users.getUsers(false, {
        params: { inactive_only: 1 },
      });
      this.items = response.data;
    },
  },

  mounted() {
    this.fetchSignups();
  },
};
</script>

<style lang="scss" scoped>
.emails {
  min-width: 1100px;
  max-width: 1400px;
}
</style>
